<template>
  <v-container fluid>
    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="UserCouponDetail"
      @options-update="getList()"
    >
      <template #item.coupon_status="{ item }">{{ item.coupon_status | formatCouponStatus }} </template>
    </datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'UserCoupon',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '換領券ID', value: 'id' },
      { text: '換領券名稱', value: 'name' },
      { text: '建立日期', value: 'create_date' },
      { text: '訂單ID', value: 'order_id' },
      { text: '持有人會員姓名', value: 'username' },
      { text: '到期日', value: 'expired_date' },
      { text: '狀態', value: 'coupon_status' },
      { text: '', value: 'actionView', align: 'end' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    _getData() {
      return new Promise(async resolve => {
        try {
          let payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            get_user_data: true,
            get_order_id: true,
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetUserCoupons(payload, user.id, user.token)
          this.tableItemTotal = total

          const list = data.map(el => {
            return {
              id: el.id,
              name: el.coupon_data ? el.coupon_data.name : '',
              create_date: el.create_date,
              order_id: el.order_id,
              username: el.user_data ? el.user_data.name : '',
              expired_date: el.expiry_date,
              coupon_status: el.coupon_status,
              user_id: el.user_id,
            }
          })

          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          resolve([])
        }
      })
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      this._getData().then(list => {
        this.tableData = list;
        this.getTransferToData();
      })
    },

    getTransferToData(count = 0) {
      if (count < this.tableData.length) {
        this.getTransferToList(this.tableData[count].user_id, this.tableData[count].id).then(result => {
          if (result) {
            this.tableData[count].coupon_status = "transferred";
          }

          count += 1;
          this.getTransferToData(count);
        })
      } else {
        this.tableLoading = false
      }
    },

    getTransferToList(user_id, user_coupon_id) {
      return new Promise(async resolve => {
        try {
          const user = this.getCurrentUserData()
          let payload = {
            user_id: user_id,
            from_user_coupon_id: user_coupon_id,
            limit: 1,
            page: 0
          }

          const { data } = await this.$Fetcher.GetTransferRecordOfUserCoupon(payload, user.id, user.token)
          this.$common.log('-----------> Transfer TO', data)
          resolve(true)
        } catch (err) {
          resolve(false)
        }
      })
    },
  },

  async mounted() {
    this.getQueries()
  },
}
</script>
